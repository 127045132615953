import { defineStore } from 'pinia'
import { supabase } from '@/lib/supabaseClient'
import displayToast from '@/helpers/displayToast'
import type { Provider, User } from '@supabase/supabase-js'
import router from '@/router'
import { isPlatform } from '@ionic/vue'
import { useModalStore } from './modal'
import { QueryCache } from '@tanstack/vue-query'

interface State {
  loading: boolean
  loggedIn: boolean
  user: User | null
}

export const useAuthStore = defineStore('auth', {
  state: (): State => ({
    loading: false,
    loggedIn: false,
    user: null
  }),
  getters: {
    userId: ({ user }) => user?.id
  },
  actions: {
    async checkAuth() {
      this.loading = true
      const {
        data: { user }
      } = await supabase.auth.getUser()
      if (!!user) {
        this.user = { ...user }
        this.loggedIn = true
      } else {
        const queryCache = new QueryCache()
        queryCache.clear()
        this.user = null
        this.loggedIn = false
      }
      this.loading = false
      return this.loggedIn
    },
    async signInWithOTP(email: string) {
      return supabase.auth.signInWithOtp({
        email: email.toLowerCase(),
        options: {
          emailRedirectTo: isPlatform('capacitor')
            ? `crewdentials://login`
            : `${window.location.origin}/`
        }
      })
    },
    async setSession(access_token: string, refresh_token: string) {
      const { presentLoader, dismissLoader, modals } = useModalStore()
      modals.clear()
      await presentLoader()
      const { error } = await supabase.auth.setSession({ access_token, refresh_token })
      if (error) {
        console.log(error)
        dismissLoader()
        displayToast(`That didn't work, please try again`)
      } else {
        await this.checkAuth()
        dismissLoader()
        router.push({ path: '/', replace: true })
      }
    },
    async getToken() {
      const {
        data: { session: access_token }
      } = await supabase.auth.getSession()
      return access_token?.access_token
    },
    async signInWithProvider(provider: Provider) {
      let extraOptions
      if (provider === 'azure') {
        extraOptions = {
          scopes: 'email'
        }
      }
      const { error } = await supabase.auth.signInWithOAuth({
        provider,
        options: {
          redirectTo: isPlatform('capacitor')
            ? `crewdentials://login`
            : `${window.location.origin}${
                window.location.pathname.startsWith('/login') ? '/' : window.location.pathname
              }`,
          ...extraOptions
        }
      })
      if (error) {
        console.log(error)
        displayToast(`That didn't work, please try again`)
      } else {
        await this.checkAuth()
      }
    },
    async verifyOtp(email: string, token: string) {
      const {
        data: { user },
        error
      } = await supabase.auth.verifyOtp({
        email: email.toLowerCase(),
        token,
        type: 'email'
      })
      if (!!user) {
        this.user = { ...user }
        this.loggedIn = true
        return true
      } else {
        return false
      }
    },
    async signOut() {
      await supabase.auth.signOut()
      await this.checkAuth()
      const customBranding = window.localStorage.getItem('customBranding')
      window.localStorage.clear()
      if (!!customBranding) window.localStorage.setItem('customBranding', customBranding)
      window.location.replace('/login')
    }
  }
})
